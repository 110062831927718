import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { DELETE_USER, GET_USER } from '../../Const/ApiConst'
import { MdDeleteForever } from 'react-icons/md'

const instance = axios.create({
    headers: {
        'Content-Type': "application/json"
    }
})

function Users() {
    const [userList, setUserlist] = useState([])
    const fetchUsers = async () => {
        try {
            await instance.get(
                GET_USER
            ).then((res) => {
                setUserlist(res.data.data)
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    const removeUserAction = async (i, id) => {

        setUserlist((current) =>
            current.filter((userList) => userList._id !== id)
        );

        try {
            const data = {
                _id: id
            }
            await instance.post(
                DELETE_USER,
                data
            ).then(res => {
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error);
        }
    }

    const datalist = () => {
        return userList.map((res, i) => {
            return <tr key={i}>
                <td>{i+1}</td>
                <td>{res.Device_Name}</td>
                <td>{res.User_Name}</td>
                <td><MdDeleteForever
                    onClick={() => removeUserAction(i, res._id)}
                    style={{ fontSize: '20px', color: "red" }} /></td>
            </tr>

        })
    }


    return (
        <main>
            <h3 className='text-secondary'>Users</h3>
            <div className='main-card mb-4 mt-4'>
                <Link to="/Add_User" className='btn btn-info text-white'>Add User</Link>
            </div>

            <div className='main-card'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>Device Name</th>
                            <th>User Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {datalist()}
                    </tbody>
                </Table>
            </div>
        </main>
    )
}

export default Users