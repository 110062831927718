import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import Visitors from './pages/admin/Visitors'
import Users from './pages/admin/Users'
import AddUser from './pages/admin/AddUser'
import Support from './pages/admin/Support'
import Topbar from './UI/Topbar'
import './App.css'
import SecurityApp from './pages/admin/SecurityApp'

const loginStatus = sessionStorage.getItem('isLoggedin')
function App() {
    // eslint-disable-next-line
    const [isLoggedin, setIsLoggedIn] = useState(loginStatus)
    if (!isLoggedin) {
        return <Login />
    } else {
        return (
            <>
                <Topbar />
                <Routes>
                    <Route path='/Login' element={<Login />} />
                    {/* <Route path='/' element={<Home />} /> */}
                    <Route path='/' element={<Visitors />} />
                    <Route path='/User_Management' element={<Users />} />
                    <Route path='/Add_User' element={<AddUser />} />
                    <Route path='/Support' element={<Support />} />
                    <Route path='/Security_App' element={<SecurityApp />} />
                </Routes>
            </>
        )
    }
}

export default App