import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import { GET_VISITOR } from '../../Const/ApiConst'
import VisitorTableRow from './VisitorTableRow'
import { TfiReload } from 'react-icons/tfi'
import Switch from '@mui/material/Switch';

const VISITOR_URL = GET_VISITOR
const instance = axios.create({
    headers: {
        "Content-Type": 'application/json',
    }
})

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

let newtDate = new Date()
let tdate = newtDate.getDate() + 1;
let tmonth = newtDate.getMonth() + 1;
let tyear = newtDate.getFullYear();


function Visitors() {

    const [rowLimit, setRowLimit] = useState(5)
    const [visitorType, setVisitorType] = useState()
    const [fromDate, setDates] = useState(year + '-' + month + "-" + date)
    const [todate, setToDates] = useState(tyear + '-' + tmonth + "-" + tdate)
    const [visitorData, setVisitorData] = useState([])
    const [searchKey, setSearchKey] = useState()
    const [imageStatus, setImageStatus] = useState(false);


    const setTypeAction = async (e) => {
        setVisitorType(e.target.value)
        try {
            const data = {
                searchKey: searchKey,
                visitorType: e.target.value,
                rowLimit: rowLimit,
                fromDate: fromDate,
                todate: todate
            }
            await instance.post(VISITOR_URL, data)
                .then((res) => {
                    setVisitorData(res.data.alldata)
                }
                )
                .catch(error => console.log(error))
        } catch (error) {

        }

    }

    const setDate = async (e) => {
        setDates(e.target.value)
        try {
            const data = {
                searchKey: searchKey,
                visitorType: visitorType,
                rowLimit: rowLimit,
                fromDate: e.target.value,
                todate: todate
            }
            await instance.post(VISITOR_URL, data)
                .then((res) => {
                    setVisitorData(res.data.alldata)
                }
                )
                .catch(error => console.log(error))
        } catch (error) {

        }
    }

    const setTodateAction = async (e) => {
        setToDates(e.target.value)


        try {
            const data = {
                searchKey: searchKey,
                visitorType: visitorType,
                rowLimit: rowLimit,
                fromDate: fromDate,
                todate: e.target.value
            }
            await instance.post(VISITOR_URL, data)
                .then((res) => {
                    setVisitorData(res.data.alldata)
                }
                )
                .catch(error => console.log(error))
        } catch (error) {

        }
    }

    // const dateData = async () => {
    //     try {
    //         const data = {
    //             searchKey: searchKey,
    //             visitorType: visitorType,
    //             rowLimit: rowLimit,
    //             fromDate: fromDate,
    //             todate: todate
    //         }
    //         await instance.post(VISITOR_URL, data)
    //             .then((res) => {
    //                 setVisitorData(res.data.alldata)
    //             }
    //             )
    //             .catch(error => console.log(error))
    //     } catch (error) {

    //     }
    // }


    const liveSearch = (e) => {
        setSearchKey(e.target.value)
        getData()
    }

    const rowLimitsetup = async () => {
        var row = rowLimit + 5
        setRowLimit(rowLimit + 5)
        try {
            const data = {
                searchKey: searchKey,
                visitorType: visitorType,
                rowLimit: row,
                fromDate: fromDate,
                todate: todate
            }
            await instance.post(VISITOR_URL, data)
                .then((res) => {
                    setVisitorData(res.data.alldata)
                }
                )
                .catch(error => console.log(error))
        } catch (error) {

        }
    }

    const getData = async () => {
        try {
            const data = {
                searchKey: searchKey,
                visitorType: visitorType,
                rowLimit: rowLimit,
                fromDate: fromDate,
                todate: todate
            }
            await axios.post(VISITOR_URL, data)
                .then((res) => {
                    setVisitorData(res.data.alldata)
                }
                )
                .catch(error => console.log(error))
        } catch (error) {

        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

   

    const populateData = () => {
        return visitorData.map((res, i) => {
            return <VisitorTableRow obj={res} imgStatus={imageStatus} key={i} />
        })
    }

    const countEntries = () => {
        if (visitorData.length <= 1) {
            return
        } else {
            return <tr>
                <td colSpan={9} className="text-center">
                    <Button variant='outline-secondary' onClick={() => rowLimitsetup()}>Load More</Button>
                </td>
            </tr>
        }
    }

    const textStyle = {
        fontSize: '14px',
        color: '#b0b0b0',
        verticalAlign: 'middle'
    }

    return (
        <>
            <main>
                <h3 className='mb-4 text-secondary'>Visitors Details</h3>
                {/* <div className='main-card d-flex flex-row gap-4 mb-4 p-4'>
                    From Date

                    <Form.Control
                        type='date'
                        onChange={(e) => setDate(e)}
                    />
                    To Date
                    <Form.Control
                        type='date'
                        onChange={(e) => setTodateAction(e)} />
                    <Form.Select
                        onChange={(e) => setTypeAction(e)}
                        style={{ zIndex: '99' }}>
                        <option value="">All</option>
                        <option >Visitor</option>
                        <option >Delivery</option>
                        <option >Taxi</option>
                        <option >Marketing</option>
                        <option >Others</option>
                    </Form.Select>

                    <Form.Control
                        type='search'
                        placeholder='Search...'
                        onChange={(e) => liveSearch(e)} />

                </div> */}
                <div className='main-card'>

                    <div className='visitorTableActionSection'>
                        From Date

                        <Form.Control
                            type='date'
                            onChange={(e) => setDate(e)}
                        />
                        To Date
                        <Form.Control
                            type='date'
                            onChange={(e) => setTodateAction(e)} />
                        <Form.Select
                            onChange={(e) => setTypeAction(e)}
                            style={{ zIndex: '99' }}>
                            <option value="">All</option>
                            <option >Visitor</option>
                            <option >Delivery</option>
                            <option >Taxi</option>
                            <option >Marketing</option>
                            <option >Others</option>
                        </Form.Select>

                        <Form.Control
                            type='search'
                            placeholder='Search...'
                            onChange={(e) => liveSearch(e)} />
                        <Button
                            className='d-flex align-items-center gap-2'
                            onClick={() => getData()}><TfiReload /> </Button>
                    </div>


                    <hr />
                    <Table responsive>
                        <thead>
                            <tr style={textStyle}>
                                <th>
                                    <Switch
                                        onClick={(imageStatus) => setImageStatus(imageStatus.target.checked)}
                                    />
                                    Photo</th>
                                <th>Type</th>
                                <th>Contact Number</th>
                                <th>Name</th>
                                <th>Apartment</th>
                                <th>Vehicle</th>
                                <th>Meeting Person</th>
                                <th>Purpose of visit</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {populateData()}
                            {countEntries()}
                        </tbody>
                    </Table>
                </div>
            </main>
        </>
    )
}

export default Visitors