import React from 'react'
import downloadApp from '../../images/Download_App.png'
import FillApp from '../../images/fill_fields.png'
import UploadPhoto from '../../images/upload_photo.png'
import Apk from '../../Apk/VMS.apk'

function SecurityApp() {

    const imageStyle = {
        width: '250px'
    }

    return (
        <main>
            <h3  className='text-secondary'>VMS Gate App</h3>

            <div className='d-flex justify-content-around main-card mt-4 p-4'>
                <div>
                    <h5 className='text-center'>Download app from below link</h5>
                    <img src={downloadApp} alt='' style={imageStyle} />
                </div>
                <div>
                    <h5 className='text-center'>Fill Fields</h5>
                    <img src={FillApp} alt='' style={imageStyle} />
                </div>
                <div>
                    <h5 className='text-center'>Upload Photo</h5>
                    <img src={UploadPhoto} alt="" style={imageStyle} />
                </div>
            </div>

            <div className='mt-4 p-4 main-card'>
                Link : <a href={Apk} target='_blank' rel='noreferrer'>Download Here</a>
            </div>
        </main>
    )
}

export default SecurityApp