import React, { useState } from 'react'
import { BiLogOutCircle } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'
import MiniSidebar from './MiniSidebar'
import Sidebar from './Sidebar'

function Topbar() {
  const [sidebarStatus, setSidebarStatus] = useState(false)
  const Navigate = useNavigate()

  const sidebar = () => {
   if (sidebarStatus) {
     return <MiniSidebar />
   }
  }

  const logoutAction = () => {
    sessionStorage.clear()
    Navigate('/Login')
  }
  return (
    <>
      <nav>
        <div>
          <GiHamburgerMenu
            className='hamburger'
            onClick={()=> setSidebarStatus(!sidebarStatus)}
          />
        </div>
        <div>
          <strong
            className='text-secondary'
            onClick={logoutAction}
          >Logout <BiLogOutCircle />
          </strong>
        </div >
      </nav >
      <div className='miniSidebarContainer'>
        {sidebar()}
      </div>
      <Sidebar />
    </>
  )
}

export default Topbar