import { TextField } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ADD_USER } from '../../Const/ApiConst'


function AddUser() {
    const navigate = useNavigate()
    const [userName, setUserName] = useState()
    const [deviceName, setDeviceName] = useState()
    const [password, setPassword] = useState()

    const data = {
        userName: userName,
        deviceName: deviceName,
        password: password
    }

    const save_user = async (e) => {
        e.preventDefault()
        if (userName === undefined || userName === "") {
            setUserName("")
        }

        if (deviceName === undefined || deviceName === "") {
            setDeviceName("")
        }

        if (password === undefined || password === "") {
            setPassword("")
        }

        if (userName !== "" && deviceName !== "" && password !== "" && userName !== undefined && deviceName !== undefined && password !== undefined) {
            const URL = ADD_USER;
            try {
                await axios.post(
                    URL,
                    data
                ).then(res => {
                    navigate('/User_Management')
                }).catch(error => console.log(error))

            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <>
            <main>
                <h3 className='text-secondary'>Add new User</h3>
                <Form onSubmit={(e) => save_user(e)} className="main-card">
                    <Form.Group className='adduserrow'>
                        <Form.Group className='col-4'>
                            Personal Details
                        </Form.Group>
                        <Form.Group>
                            <Form.Group className='adduserrow w-100'>
                                <Form.Group >
                                    <TextField
                                        className='w-100'
                                        label="Device Name"
                                        error={deviceName === ""}
                                        helperText={deviceName === "" ? '* Device Required' : ' '}
                                        onChange={(e) => setDeviceName(e.target.value)} />
                                </Form.Group>

                                <Form.Group >
                                    <TextField
                                        className='w-100'
                                        label="User Name"
                                        error={userName === ""}
                                        helperText={userName === "" ? '* Username Required' : ' '}
                                        onChange={(e) => setUserName(e.target.value)} />
                                </Form.Group>
                                <Form.Group >
                                    <TextField
                                        className='w-100'
                                        label="Password"
                                        error={password === ""}
                                        helperText={password === "" ? '* Password Required' : ' '}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                            </Form.Group>
                        </Form.Group>
                    </Form.Group>
                    <Button type='submit'>Save</Button>
                </Form>
            </main>
        </>
    )
}

export default AddUser