import { Alert, AlertTitle, TextField } from '@mui/material'
import { height, width } from '@mui/system'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


function Login() {
  const defuname = "Anvita_Admin"
  const defpassword = 'anv_admin_123'
  const [userName, setUserName] = useState()
  const [password, setPassword] = useState()

  const [unameError, setunameError] = useState()
  const [passworderror, setpassworderror] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const Navigate = useNavigate()
  const loginAction = (e) => {
    e.preventDefault()

    if (userName === undefined || userName === '') {
      setunameError("Required")
    } else if (password === undefined || password === '') {
      setpassworderror("Required")
    } else if (userName === defuname && password === defpassword) {
      sessionStorage.setItem('isLoggedin', true)
      Navigate('/')
      // window.location.reload()
    } else {
      setErrorMessage('User name and password are not matching')
    }


  }

  const mainDiv={
    position:'absolute',
    width:'100%',
    height:'100%'
  }

  return (
    <div className='d-flex gap-4 align-items-center' style={mainDiv}>
      <div  className='loginBgImg'>
        <h1>Welcome to VMS</h1>
      </div>
      <Form onSubmit={(e) => loginAction(e)} className='w-50 p-4'>
        {errorMessage ? (
          <Alert severity='error' className='mb-4'>
            <AlertTitle>Error</AlertTitle>
            {errorMessage}</Alert>
        ) : (<></>)}
        <Form.Group className='mb-4'>
          <TextField
            label="User ID"
            className='w-100'
            onChange={(e) => setUserName(e.target.value)} />
        </Form.Group>
        <Form.Group className='mb-4'>
          <TextField label="Password" className='w-100' type='password' onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>
        <Button type='submit' className='w-100'>Login</Button>
      </Form>
    </div>
  )
}

export default Login