import React from 'react'
import SupportImage from '../../images/support.jpg'
import { FiPhoneCall } from 'react-icons/fi'
import { HiOutlineMail } from 'react-icons/hi'

function Support() {
  const supportImage = {
    width: "100%"
  }
  return (
    <main>
      <h3 className='text-secondary'>Support Center</h3>
      <div className='row'>
        <div className='col-6'>
          <img src={SupportImage} alt="" style={supportImage} />
        </div>
        <div className='col-6'>
          <h5>24 x 7 Support</h5>
          {/* <div className='main-card'>
            <Form.Control placeholder='Title'/>
            <textarea className='form-control mt-2'/>
            <Button className='mt-2'>Save</Button>
          </div> */}

          <div className='main-card mt-4'>
            <strong>Feel free to contact us.</strong>
            <div>We are excited to serve you better!</div>
            <p />
            <div> <FiPhoneCall /> +91 903-799-0143</div>
            <div><HiOutlineMail /> support@ahydratech.com</div>
          </div>
        </div>
      </div>

    </main>
  )
}

export default Support