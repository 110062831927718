import React from 'react'
import NoImage from '../../images/No_Image_Available.jpg'
import { Modal } from 'react-bootstrap'

function VisitorTableRow(props) {
    const {
        Image,
        Visitor_Type,
        Name,
        Apartment_No,
        Contact_Number,
        Vehicle_No,
        Creation_Date,
        Purpose_of_Visit,
        Whom_To_Meet } = props.obj
    const [modalShow, setModalShow] = React.useState(false)

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="w-100 d-flex justify-content-center p-0">
                    <img
                        src={'data:image/png;base64,' + Image}
                        alt='' />
                </Modal.Body>

            </Modal>
        );
    }


    var date = new Date(Creation_Date);
    // var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = date.getFullYear();
    // var month = months[date.getMonth()];
    var dateVal = date.getDate();

    var hours = date.getHours()
    var minutes = date.getMinutes() - 30
    var formattedTime = hours - 5 + ":" + minutes + ":" + date.getSeconds()

    var formattedDate = dateVal + '/' + (date.getMonth() + 1) + '/' + year;

    return (
        <tr>
            <td>
                {
                    props.imgStatus ? (
                        <>
                            {Image === '' ? (
                                <img src={NoImage} alt='' className='visitorImage' />
                            ) : (
                                <img
                                    src={'data:image/png;base64,' + Image}
                                    alt=''
                                    className='visitorImage'
                                    onClick={() => setModalShow(true)}
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )
                }

            </td>
            <td>{Visitor_Type}</td>
            <td>{Contact_Number}</td>
            <td>{Name}</td>
            <td>{Apartment_No}</td>
            <td >{Vehicle_No}</td>
            <td>{Whom_To_Meet}</td>
            <td>{Purpose_of_Visit}</td>
            <td>
                {formattedDate}
                <br />
                {formattedTime}
            </td>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </tr>
    )

}


export default VisitorTableRow