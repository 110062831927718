import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Ripples from 'react-ripples'
import { IoIosPeople } from 'react-icons/io'
import { GiPoliceOfficerHead } from 'react-icons/gi'
import { FaMobileAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import support_icon from '../images/support.png'
import Logo from '../images/logo-color.png'


function Sidebar() {
    // const [userName, setUserName] = useState('')

    useEffect(() => {
        // setUserName('New User')
    }, [])

    // const unameIcon = () => {
    //     return userName.substring(1, 0)
    // }



    return (

        <aside>
            <div className='mt-4'>
                <div className='app-title d-flex flex-column gap-0 p-4'>
                    <img src={Logo} alt="" className='logo_image' />
                    <div className='d-flex flex-column gap-0 align-items-end pe-3'>
                        <div
                            style={{ height: '20px',fontSize:'1.2rem' }}>Anvita Residency</div>
                        <small
                            style={{ fontWeight: '400' }}>Aluva</small>
                    </div>
                </div>
            </div>
            {/* <div className='sidebar-user-section mt-4'>
                <div className='sidebar-name-icon'>
                    <div className='sidebar-icon-text'>{unameIcon()}</div>
                </div>
                <div className='sidebar-name-title'>{userName}</div>
            </div> */}
            <ul className='mt-4'>
                <li>
                    <Ripples className='w-100'>
                        <NavLink to="/" className={({ isActive }) => isActive ? "sidebar-items sidebar-items-active" : "sidebar-items"}>
                            <IoIosPeople className='sidebar-icon' />
                            Visitors
                        </NavLink>
                    </Ripples>
                </li>
                <li>
                    <Ripples className='w-100'>
                        <NavLink to="/User_Management" className={({ isActive }) => isActive ? "sidebar-items sidebar-items-active" : "sidebar-items"}>
                            <GiPoliceOfficerHead className='sidebar-icon' />
                            User Management
                        </NavLink>
                    </Ripples>
                </li>
                <li>
                    <Ripples className='w-100'>
                        <NavLink to="/Security_App" className={({ isActive }) => isActive ? "sidebar-items sidebar-items-active" : "sidebar-items"}>
                            <FaMobileAlt className='sidebar-icon' />
                            VMS Gate Application
                        </NavLink>
                    </Ripples>
                </li>
            </ul>
            <hr className='mt-4' />
            <div className='d-flex flex-column align-items-center mt-4'>
                <h4 className='text-secondary'>Trouble using App?</h4>
                <div className='text-secondary'>Feel free to contact us</div>
                <img src={support_icon} alt='' style={{ width: '100%' }} />
                <Link to="support" className='btn btn-success'>Contact us</Link>
            </div>
        </aside >

    )
}

export default Sidebar