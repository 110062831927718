import React from 'react'
import { IoIosPeople } from 'react-icons/io'
import { GiPoliceOfficerHead } from 'react-icons/gi'
import { FaMobileAlt } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import Ripples from 'react-ripples'

function MiniSidebar() {
    return (
        <div>
            <ul className='mt-4'>
                <li>
                    <Ripples className='w-100'>
                        <NavLink to="/" className={({ isActive }) => isActive ? "sidebar-items sidebar-items-active" : "sidebar-items"}>
                            <IoIosPeople className='sidebar-icon' />
                            Visitors
                        </NavLink>
                    </Ripples>
                </li>
                <li>
                    <Ripples className='w-100'>
                        <NavLink to="/User_Management" className={({ isActive }) => isActive ? "sidebar-items sidebar-items-active" : "sidebar-items"}>
                            <GiPoliceOfficerHead className='sidebar-icon' />
                            User Management
                        </NavLink>
                    </Ripples>
                </li>
                <li>
                    <Ripples className='w-100'>
                        <NavLink to="/Security_App" className={({ isActive }) => isActive ? "sidebar-items sidebar-items-active" : "sidebar-items"}>
                            <FaMobileAlt className='sidebar-icon' />
                            VMS Gate Application
                        </NavLink>
                    </Ripples>
                </li>
            </ul>
        </div>
    )
}

export default MiniSidebar